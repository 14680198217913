<template>
    <v-row>
        <v-col cols="12">
            <v-btn :loading="carregando" @click="salvar" :disabled="carregando" fixed bottom right fab color="primary"><v-icon dark>mdi-content-save</v-icon></v-btn>
            <v-card>
                <v-toolbar color="primary" dark flat height="auto">
                    <v-toolbar-title class="py-2">Visão geral</v-toolbar-title>
                </v-toolbar>
                <v-progress-linear top absolute indeterminate color="#F44336" :active="carregando" />
                <v-card-text>
                    <p class="text--primary">
                        Tome algumas precauções ao definir uma senha, um usuário malicioso ou não qualificado com acesso pode levar a resultados desastrosos,
                        portanto se deve tomar alguns cuidados ao escolher uma senha que não seja adivinhada facilmente. A senha não deve ser uma palavra encontrada em dicionário, ou que possa ser facilmente associada a você.
                    </p>
                    <p class="text--primary">Uma boa senha conterá uma mistura de letras, números e pontuação com no mínimo oito caracteres, e deverá ser modificada em intervalos regulares.</p>
                    <p class="text--primary">E principalmente, não compartilhe com ninguém.</p>
                    <v-form>
                        <v-row>
                            <v-col cols="12" md="4"><v-text-field :disabled="carregando" hide-details type="password" label="* Senha atual:" v-model="registro.asenha" /></v-col>
                            <v-col cols="12" md="4"><v-text-field :disabled="carregando" hide-details type="password" label="* Nova senha:" v-model="registro.nsenha" /></v-col>
                            <v-col cols="12" md="4"><v-text-field autocomplete="new-password" :disabled="carregando" hide-details type="password" label="* Confirme para não esquecer:" v-model="registro.rsenha" /></v-col>
                            <v-col cols="12" class="pb-0 mt-3" v-if="erro"><v-alert dense type="error" v-html="erro"/></v-col>
                            <v-col cols="12" class="pb-0 mt-3" v-if="sucesso"><v-alert dense type="success" v-html="sucesso"/></v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import axios from 'axios'
    import {mapState} from 'vuex'

    export default {
        name: "UsuarioSenhaForm",
        data() {
            return {
                registro : {},
                erro : null,
                sucesso : null,
                migalhaPao : [],
                carregando : false
            }
        },
        computed : {
            ...mapState(['apiUrl', 'usuarioSessao'])
        },
        methods : {
            salvar() {
                this.carregando = true
                return axios.post(`${this.apiUrl}usuario/senha/alterar`, this.registro).then( () => {
                    this.erro = null
                    this.registro = {}
                    this.sucesso = 'Senha alterada com sucesso'
                    this.carregando = false
                }).catch(e => {
                    this.erro = this.erroFormatar(e)
                    this.carregando = false
                })
            }
        },
        activated : function() {
            this.migalhaPao = [
                {title : 'Dashboard', to : '/'},
                {title : 'Alterar minha senha', to : null}
            ]
            this.registro.usuario = this.usuarioSessao.usuario
            this.registro.email   = this.usuarioSessao.email
        }
    }
</script>

<style scoped>

</style>
